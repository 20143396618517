













































































































































































































































































import ModuleBindings from '@/includes/logic/Modules/mixins/ModuleBindings'
import TariffsTagsHelper from '@/mixins/TariffsTagsHelper'
import NotAvailableOptionsOverlay from '@/components/NotAvailableOptionsOverlay.vue'
import { InputSetups } from '@/mixins/input-setups'
import Placeholders from '@/mixins/placeholders/placeholders'
import ReferralModuleBuilder from '@/includes/logic/Modules/models/modules/ReferralModule/ReferralModuleBuilder'
import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import GoBackButton from '../components/PageTitleButtons/GoBackButton.vue'
import SaveModuleButton from '../components/PageTitleButtons/SaveModuleButton.vue'
import ModuleConfigSettingsWrapper from '@/components/Modules/components/ModuleConfigSettingsWrapper.vue'
import ModuleConfigSettings from '@/components/Modules/components/ModuleConfigSettings.vue'

import MultiMessageEditorWithMediaInput from 'piramis-base-components/src/components/Pi/fields/NewMultiMessageEditorWithMediaInput/MultiMessageEditorWithMediaInput.vue'
import SwitchInput from 'piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue'
import { MediaEditorMode } from 'piramis-base-components/src/components/NewMessageEditors/types'
import SelectInput from 'piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue'
import { TagData } from 'piramis-base-components/src/components/Tags/types'
import Tags from 'piramis-base-components/src/components/Tags/Tags.vue'

import { Component, Mixins, VModel } from 'vue-property-decorator'

@Component({
  components: {
    CenteredColumnLayout,
    NotAvailableOptionsOverlay,
    MultiMessageEditorWithMediaInput,
    SwitchInput,
    SelectInput,
    GoBackButton,
    SaveModuleButton,
    Tags,
    ModuleConfigSettingsWrapper,
    ModuleConfigSettings,
  },
  data() {
    return {
      MediaEditorMode
    }
  }
})
export default class ReferralModuleView extends Mixins(ModuleBindings, InputSetups, TariffsTagsHelper, Placeholders) {
  @VModel() module!: ReferralModuleBuilder

  willBeRemovedTag: Array<TagData> = [
    {
      text: this.$t('field_will_be_removed_soon').toString(),
      color: 'rgba(var(--a-danger),1)'
    }
  ]

  get refSystemTag() {
    return this.module.tariffTags()
  }

  isTriggerSet(key: keyof Pick<ReferralModuleBuilder['model']['params'], 'referral_private_trigger' | 'referral_first_private_trigger' | 'referral_not_first_private_trigger'>): boolean {
    const { params } = this.module.model;
    const field = params?.[key]

    return !!field && !!field.length
  }

  mounted() {
    if (this.refSystemTag) {
      this.$baseTemplate.saveButton.hide()
    } else {
      this.$baseTemplate.saveButton.show()
    }
  }
}
